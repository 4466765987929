.modal-26w {
  width: 26% !important;
}
.modal-35w {
  width: 432px !important;
}
.form-popup {
  width: 100%;
  height: 2.4rem;
  line-height: 16px;
  font-size: 15px;
  border-radius: 6px;
  padding: 11px;
  font-weight: 400;
  border: 1px solid #dddfe2;
  outline: none;
  font-family: inherit;
  display: "block";
}
.lable-popup {
  color: #606770;

  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 2px;
}
.btnStyle {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  /*text-decoration: underline;*/
  cursor: pointer;
}
.footer-element {
  width: 100%;
  height: 100px;
  background: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-phone-number input:focus {
  border: 1px solid #dddfe2 !important;
  box-shadow: none !important;
}
