.input-icon {
  color: #00c6ff;
  z-index: 10;
  margin-left: -25px;
  font-size: 22px !important;
  border: 0;
  background-color: #fff;
  background-color: transparent;
  margin-bottom: 1px;
}

.list-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
  margin-top: 45px;
}

.channel-oval {
  background-image: url("../../img/channels.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
}
