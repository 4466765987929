.body {
  min-height: 250px;
}
.contact-form{
  height: 50px !important;
  border-radius: 8px !important;
}

@media screen and (max-width: 767px) {
  .devices {
    display: none;
  }

  .phone_image {
    text-align: center;
  }

  .image_text{
    font-size: 42px;
    text-align: center;
    margin: 10% 19% 0% 18%;
    width: 60%;
  }

  .image_text_span{
    font-size: 23px;
    font-weight: bold;
    overflow-wrap: break-word;
  }

  .first_class{
    margin: 6%;
    font-size: 30px;
  }

  .second_class{
    margin: 6%;
  }

  .form_text{
    width: 80%;
    background-color: #00c7ff;
    color: white;
    margin: 10%;
  }

  .form_class{
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .form_class .col-md-6 {
    margin-bottom: 5%;
  }
  
}

@media screen and (min-width: 768px) {
  .trust {
    margin: 18% 0 0 15%;
  }

  .internet{
    margin-left: 15%;
  }

  .image_text {
    font-size: 42px;
    text-align: center;
    margin: 10% 19% 0% 18%;
  }

  .image_text_span{
    font-size: 39px;
    font-weight: bold;
    overflow-wrap: break-word;
  }

  .first_class{
    margin: 12% 23% 0%;
    font-size: 40px;
  }

  .second_class{
    margin: 3% 23% 12%;
  }

  .form_text{
    width: 46%;
    background-color: #00c7ff;
    color: white;
    margin-top: 4%;
    margin-left: 27%;
  }

  .form_class{
    width: 40%;
    margin-left: 30%;
    margin-top: 2%;
  }
}