.location-oval {
  background-image: url("../../img/location.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 57px;
}

.location-icon {
  height: 57px;
  width: 57px;
  border-radius: 100px;
  background-image: url("../../img/location1x.png"),
    linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%);
  background-repeat: no-repeat;
  background-position: center;
}
