.input-icon {
  color: #00c6ff;
  z-index: 10;
  margin-left: -25px;
  font-size: 22px !important;
  border: 0;
  background-color: #fff;
  background-color: transparent;
  margin-bottom: 1px;
}

.list-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 380px;
  margin-top: 45px;
}

.feed-oval {
  background-image: url("../../img/feed.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55px;
}

.selectChannel .rbt .form-control {
  height: auto !important;
}

.selectChannel .rbt {
  width: 100% !important;
}
