/*
    DEMO STYLE
*/

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
body {
    font-family: 'Poppins', sans-serif;
    /* background: #fafafa; */
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}
.first-navbar{
        background: #f8f9fa !important;
            /* border-bottom: 1px solid #eee; */
            /* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); */
            font: 15px HK Grotesk, -apple-system, BlinkMacSystemFont, Helvetica, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
            /* position: relative;
            width: 100%;
            z-index: 200; */
            position: fixed;
                z-index: 101;
                margin: 0;
                width: 100%;
           
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
   
  

    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: 2000px;
    padding: 0;
    position: relative;

}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: rgb(200, 200, 200);
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: rgb(200, 200, 200);
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}

/* aside */


body {
    margin: 0 20px;
  }
  
  .headers {
    position: sticky;
    top: 0;
    background: rgb(0, 200, 255);
    height: 60px;
    margin-bottom: 20px;
    padding: 0 20px;
   
    
    /* for positioning text, links, etc */
    display: flex;
    align-items: center;
  }
  
  .flex-container {
    display: flex;
  }
  
  aside {
    position: sticky;
    top: 80px; /* the height of the header (60px) + its bottom margin (20px) */
    background-color: rgb(239, 239, 239);
    padding: 20px;
    width: 30%;
    height: 500px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 8%;
  }
  
  main {
    padding-left: 20px;
    flex-grow: 1;
  }
  
  .content {
    background: rgb(200, 200, 200);
    height: 300px;
    padding: 20px;
    margin-bottom: 20px;
  }


  .profile {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #efefef;
}

.gray-darkish {
    color: #585858;
}

.profile-sidebar-user-name {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin-top: 12px;
}


.nav-item-divider {
    height: 1px;
    margin: 1rem 0;
    /* overflow: hidden; */
    background-color: rgba(76, 127, 153, 0.3);
}