.footer {
  background: #ffffff;
  border-top: 2px solid;
  min-height: 150px;
  position: relative;
}

.footer-link-container {
  margin: 40px 40px 5px 40px !important;
}

.Logo_footer {
  height: 18px;
}

.footer_Logo_div {
  margin: auto;
  width: 100%;
  text-align: center;
}

.footer_symbol_div {
  margin: auto;
  text-align: center;
}

.footer_nav_container {
  margin: auto;
  display: inline-flex;
  width: 85%;
  float: right;
}

.footer_nav_item {
  margin: 3% auto;
  color: #4a4a4a;
  /* font-family: proximaNova; */
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding: 0;
  text-align: center;
}

.copyRight-text {
  color: #4a4a4a;
  /* font-family: "proximaNova"; */
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  width: 100%;
}

.footer_nav_item_link {
  color: #4a4a4a;
  /* font-family: PTSansNarrow; */
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  cursor: pointer;
}

.logo_footer {
  width: 50px;
}

.logo_footer2 {
  width: 70px;
}

@media screen and (min-width: 768px) {
  .google_link{
    height: 120px;
    float: right;
    margin-right: 3%;
  }
}

@media screen and (max-width: 767px) {
  .google_link{
    height: 120px;
    margin-right: 3%;
  }
}