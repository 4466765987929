.webShare-link {
  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 80%;
  }
  @media (min-width: 992px) {
    width: 40%;
  }
  @media (min-width: 1200px) {
    width: 40%;
  }
}



.mainContainer{
  border-radius: 10px;
}