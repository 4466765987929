@font-face {
  font-family: AlternateGothicNo1D;
  src: url("./fonts/Alternate\ Gothic\ No1\ D\ Regular.otf");
}

@font-face {
  font-family: proximaNova;
  src: url("./fonts/ProximaNovaRegular.ttf");
}

@font-face {
  font-family: Lato;
  src: url("./fonts/Lato-Light.ttf");
}

@font-face {
  font-family: PTSansNarrow;
  src: url("./fonts/PT_Sans-Narrow-Web-Regular.ttf");
}

@font-face {
  font-family: DIN Neuzeit Grotesk LT W01 BdCn;
  src:  url("./fonts/din_font/din_font.ttf"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}

@font-face {font-family: "poppins-semibold";
  src: url("./fonts/pop_semi_bold/pop_semi_bold.eot"); /* IE9*/
  src: url("./fonts/pop_semi_bold/pop_semi_bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/pop_semi_bold/pop_semi_bold.woff2") format("woff2"), /* chrome、firefox */
  url("./fonts/pop_semi_bold/pop_semi_bold.woff") format("woff"), /* chrome、firefox */
  url("./fonts/pop_semi_bold/pop_semi_bold.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("./fonts/pop_semi_bold/pop_semi_bold.svg#poppins-semibold") format("svg"); /* iOS 4.1- */
}
@font-face{
  font-family:"Lulo-Clean-W01-One-Bold";
  src:url("./fonts/lulo_clean/lulo_clean.eot?#iefix");
  src:url("./fonts/lulo_clean/lulo_clean.eot?#iefix") format("eot"),
  url("./fonts/lulo_clean/lulo_clean.woff2") format("woff2"),
  url("./fonts/lulo_clean/lulo_clean.woff") format("woff"),
  url("./fonts/lulo_clean/lulo_clean.ttf") format("truetype"),
  url("./fonts/lulo_clean/lulo_clean.svg#Lulo-Clean-W01-One-Bold") format("svg");
}
@font-face{
  font-family:"Avenir-LT-W01_85-Heavy1475544";
  src:url("./fonts/avenir_85_ heavy/avenir_85_ heavy.eot?#iefix");
  src:url("./fonts/avenir_85_ heavy/avenir_85_ heavy.eot?#iefix") format("eot"),
  url("./fonts/avenir_85_ heavy/avenir_85_ heavy.woff2") format("woff2"),
  url("./fonts/avenir_85_ heavy/avenir_85_ heavy.woff") format("woff"),
  url("./fonts/avenir_85_ heavy/avenir_85_ heavy.ttf") format("truetype"),
  url("./fonts/avenir_85_ heavy/avenir_85_ heavy.svg#Avenir-LT-W01_85-Heavy1475544") format("svg");
}
@font-face{
  font-family:"Avenir-LT-W01_35-Light1475496";
  src:url("./fonts/avenir_35_light/avenir_35_light.eot?#iefix");
  src:url("./fonts/avenir_35_light/avenir_35_light.eot?#iefix") format("eot"),
  url("./fonts/avenir_35_light/avenir_35_light.woff2") format("woff2"),
  url("./fonts/avenir_35_light/avenir_35_light.woff") format("woff"),
  url("./fonts/avenir_35_light/avenir_35_light.ttf") format("truetype"),
  url("./fonts/avenir_35_light/avenir_35_light.svg#Avenir-LT-W01_35-Light1475496") format("svg");
}
@font-face{
  font-family:"Proxima-N-W01-Reg";
  src:url("./fonts/proxima_reg/proxima_reg.eot?#iefix");
  src:url("./fonts/proxima_reg/proxima_reg.eot?#iefix") format("eot"),
  url("./fonts/proxima_reg/proxima_reg.woff2") format("woff2"),
  url("./fonts/proxima_reg/proxima_reg.woff") format("woff"),
  url("./fonts/proxima_reg/proxima_reg.ttf") format("truetype"),
  url("./fonts/proxima_reg/proxima_reg.svg#Proxima-N-W01-Reg") format("svg");
}


html, body{
  height:100%;
}
.flex_display {
  display: flex !important;
}
.page{
  height: 100%;
}
.newsfeed-container{

    padding: 20px 15px;
  
   
}
.newsfeed-sidebar{
position: fixed;
  /* display: block; */
  /* vertical-align: top; */
  float: left;
  top: 450px;
}

.newsfeed-content-pane {
  
  display: block;
  vertical-align: top;
  float: left;
  padding: 0 15px;
  max-width: 95.666667%
}
.container-bg {
  /* background-color: rgb(221 244 250); */
  padding-bottom: 54px
  /* padding: 80px 0; */
}
.popBox{
      display: flex;
        margin-bottom: 12px;
        /* width: 100%; */
     
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, .15);
}
.setBottom{
      margin-top: 14px;
  display: flex;
    margin-bottom: 12px;
    /* width: 100%; */
  
    justify-content: flex-end;
    border-top: 1px solid rgba(0, 0, 0, .15);
}
.container-white-bg {
  background-color: #fff;
  padding: 80px 0;
  min-height: 550px;
}

.form-rectangle {
  min-height: 474px;
  margin: auto;
  border: 1px solid #979797;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  margin: auto;
}
.set_box{
  display: block;
  margin: auto;
  width: 62%;
}
.form-header {
  height: 122px;
  background: linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%);
  padding: 31px 20px;
}
.form-headers {
  height: 118px;
  background: rgb(244, 244, 244);
  padding: 31px 20px;

}
.password-headers{
  height: 118px;
    background: rgb(244, 244, 244);
    padding: 31px 50px;
    text-align: center;
}
.logo-set{
  width: auto;
    height: 49px;
  display: block;
    margin-left: auto;
    margin-right: auto;
   
    vertical-align: middle;
}
.center-message{
  font-size: 28px;
    line-height: 86px;
  text-align: center;
}
.success-img{
  width: auto;
    height: 127px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  
    vertical-align: middle;
}
.showMessage{
  color: #fff;
    text-align: center;
    padding: 10px;
    line-height: 1.8;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    background: #7ed321;
}
.form-headers > h3 {
font-size: 22px;
  padding: 0 38px;
  line-height: 30px;
  margin: 4px 82px 11px;
}
.set_text{
line-height: 3.25;
  font-weight: 300;
}
.set_data{
  width: 52%;
  margin-left: auto;
    margin-right: auto;
}
.log_img{
  width: 298px;
    height: 71px;
}
.container{
  margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    position: relative;
    width: auto;
}
.container-login{
display: flex;
  flex-direction: column;
  font-size: 14px;
  font-size: var(--smallText);
  margin-bottom: -224px;
  max-width: 760px;
  min-height: calc(100vh - 80px);
  text-align: center;
}
.set-password{
 
       color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
     
      text-decoration: none;
    
}
.center-texts {
  font-size: 22px;

  line-height: 30px;
}
 .set-box {
      width: 28%;
        min-height: 349px;
        position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          /* border: 5px solid #FFFF00; */
          padding: 10px;
 }
@media screen and  (min-width: 650px) and (max-width: 963px){
  .set-box{
    width: 50%;
  }
}
@media screen and (max-width: 650px) {
  .set-box {
    width: 89%;
  }
}
@media screen and  (max-width: 650px) {
  .center-texts {
    font-size: 1em;
    font-weight: 600
  }
}
@media screen and (max-width: 650px) {
  .message {
    font-size: 12px;
  }
}
.set-button{
  border: 0;
  border-radius: 2px;
    padding: 14px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    
    transition: 0.2s ease-in-out;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    text-transform: uppercase;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  
}
.form-header > h3 {
  color: #ffffff;
  font-family: "proximaNova";
  font-size: 40px;
  font-weight: 100 !important;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
}

.form-header > p {
  color: #ffffff;
  font-family: "proximaNova";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.form-container {
  padding: 20px;
}

.form-body {
  margin: 20px 60px;
  font-family: proximaNova !important;
}

.form-lText {
  color: #4a4a4a;
  font-family: PTSansNarrow;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  margin: auto;
  margin-top: 80px;
  padding: 0 50px;
  padding-bottom: 30px;
}
.form-l {
  color: #090909;
  /* font-family: PTSansNarrow; */
  /* font-size: 20px; */
  /* font-weight: bold; */
  line-height: 26px;
  text-align: center;
  margin: auto;
  margin-top: 80px;
  padding: 0 50px;
  padding-bottom: 30px;
}
.form-lable {
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

.form-input {
  border: 0 !important;
  overflow: hidden;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
  display: block;
  width: 100%;
  font-family: proximaNova !important;
  padding: 2px 2px !important;
  height: 22px;
  color: #4a4a4a;
  font-size: 20px !important;
  font-weight: bold;
}

.form-btn {
  padding: 5px 35px !important;
  border-radius: 8px !important;
  background-color: #00c6ff;
  color: #ffffff;
  font-size: 18px !important;
  margin: 10px 0px;
  text-transform: uppercase;
}

.form-btn-sm {
  padding: 5px 12px !important;
  border-radius: 5px !important;
  background-color: #00c6ff !important;
  border: 1px solid #00c6ff !important;
  color: #ffffff;
  font-size: 14px !important;
  text-transform: uppercase;
}

.form-btn-sms {
  padding: 2px 12px !important;
  border-radius: 5px !important;
  background-color: #00c6ff !important;
  border: 1px solid #00c6ff !important;
  color: black;
  font-size: 20px !important;
  text-transform: uppercase;
}

.form-link {
  height: 27px;
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  margin: 10px 0;
  cursor: pointer;
}

.rbt-input-hint-container > .form-control {
  border: 0 !important;
  border-bottom: 1px solid #4a4a4a !important;
  padding: 5px 2px;
}

.rbt-input {
  border: 0;
  border-radius: 0 !important;
  box-sizing: border-box;
  border-bottom: 1px solid #4a4a4a !important;
  display: block;
  width: 100%;
  font-family: proximaNova !important;
  padding: 5px 2px;
  color: #4a4a4a;
  font-size: 20px;
  font-weight: bold;
}

.rbt > .form-control {
  border: 0;
  box-sizing: border-box;
  border-bottom: 1px solid #4a4a4a;
  border-radius: 0;
  display: block;
  width: 100%;
  font-family: proximaNova !important;
  padding: 5px 2px;
  color: #4a4a4a;
  font-size: 20px;
  font-weight: bold;
}

.rbt > .form-control:focus {
  box-shadow: none !important;
  border-bottom: 2px solid #4a4a4a !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-bottom: 2px solid #4a4a4a !important;
}

.rbt-input-wrapper > .rbt-token {
  border: 0;
  border-radius: 18px;
  color: #4a4a4a;
  background-color: #9ce7fd;
  display: inline-block;
  line-height: 1em;
  margin: 3px !important;
  position: relative;
  font-family: proximaNova !important;
  padding: 3px 20px;
  padding-right: 25px;
}

.rbt-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.createPost_typeahead .rbt-input-hint-container > .rbt-input-main {
  border: 0;
  color: #4a4a4a;
  display: inline-block;
  margin: 0 3px 3px 0;
  position: relative;
  font-family: proximaNova !important;
  font-size: 18px;
}

.rbt-input-hint-container > .rbt-input-main {
  border: 0;
  color: #4a4a4a;
  display: inline-block;
  margin: 0 3px 3px 0;
  position: relative;
  font-family: proximaNova !important;
  font-size: 25px;
}

.rbt-menu > li > a {
  color: #4a4a4a;
  font-family: proximaNova !important;
  padding: 10px;
  line-height: 24px;
  font-size: 20px;
}

.rbt > .form-control:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}

.m-auto {
  margin: auto !important;
}

.rbt-token .rbt-token-remove-button {
  color: #9b9b9b;
  font-family: proximaNova;
  font-size: 24px;
  font-weight: 300;
  padding: 5px 7px !important;
}

.pill {
  border: 0;
  border-radius: 18px;
  color: #4a4a4a;
  background-color: #9ce7fd;
  display: inline-block;
  margin: 0 3px 3px 0;
  position: relative;
  padding: 4px 20px;
  padding-right: 25px;
  font-family: proximaNova;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.8;
  letter-spacing: normal;
}

.remove-btn {
  bottom: 0;
  color: #9b9b9b;
  font-family: proximaNova;
  font-size: 18px;
  font-weight: 300;
  opacity: 1;
  outline: none;
  padding: 5px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  top: -2px;
}

.show-more {
  font-family: ProximaNova;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.5;
  letter-spacing: normal;
  color: #9b9b9b;
}

.mt-10 {
  margin: 25px 0 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c7c7c7;
}

.rectangle-text {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #979797;
  height: 537px;
  overflow-y: auto;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.form-link-btn {
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--greyish-brown);
  padding: 8px 0;
  display: block;
}

.error_text {
  color: red;
}

.section-header {
  font-family: AlternateGothicNo1D;
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #00c6ff;
  text-transform: uppercase;
}

.article-headline {
  font-family: PTSansNarrow;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.oval {
  height: 122px;
  width: 122px;
  border: 3px solid #4a4a4a;
  margin: auto;
  border-radius: 50%;
}

.manage-form {
  width: 100%;
  padding: 0 50px 0 50px;
  margin-bottom: 38px;
}
.change_button{
      width: 16%;
        /* height: 49%; */
        /* margin-left: 602px; */
        /* margin-right: auto; */
        /* margin-bottom: 14px; */
        padding: 12px;
        font-weight: 500;
        color: white;
        background-color: #00c7ff;
        border: none;
        font-size: 22px;
}
.mb-16 {
  margin-bottom: 16px;
}

.mtb-60 {
  margin: 60px 0;
}

.search-input {
  font-size: 40px !important;
  font-weight: 300;
  line-height: 48px;
  border: 0 !important;
  box-sizing: border-box;
  border-bottom: 0.32mm solid #4a4a4a !important;
  border-radius: 0 !important;
  display: block;
  font-family: proximaNova !important;
  padding: 25px 5px !important;
  color: #4a4a4a;
}

::placeholder {
  color: #c1c1c1 !important;
  opacity: 1; /* Firefox */
  font-family: proximaNova !important;
  font-size: 18px !important;
  font-weight: 100;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c1c1c1 !important;
  font-family: proximaNova !important;
  font-size: 18px !important;
  font-weight: 100;
  opacity: 1;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c1c1c1 !important;
  font-family: proximaNova !important;
  font-size: 18px !important;
  font-weight: 100;
  opacity: 1;
}

.manage-form-label {
  color: #4a4a4a;
  font-family: proximaNova;
  font-size: 34px;
  font-weight: 300;
  line-height: 41px;
  text-align: center;
}

.manage-form-label:hover {
  text-decoration: none !important;
  color: #4a4a4a;
}

.manage-form-rectangle {
  width: 100%;
  border: 1px solid #eceaea;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  margin-top: 45px;
}

.manage-form-body {
  margin: 20px 30px;
  font-family: proximaNova !important;
}

.edit-icon {
  background-image: url("./img/edit.svg");
  color: #fff;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 23px 23px;
  margin-left: 45px;
}

.manage-form-header {
  height: 90px;
  background: linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%);
  padding: 5px 5px;
  border: 1px solid #00c6ff;
}

.manage-form-title {
  color: #ffffff;
  font-family: proximaNova;
  font-size: 26px;
  font-weight: 300;
  /* line-height: 85px; */
  text-align: center;
  text-transform: uppercase;
  height: 122px;
  line-height: 42px;
}
.resend_otp{

  color: #00c6ff;
  cursor: pointer;

}
.center-text{
  font-size: 22px;
      text-align: center;
    line-height: 30px;
  
}

.send-otp{
  display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -32px;
    margin-bottom: 16px;
}
.manage-form-container {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}
.rbt-input-main::placeholder {
  color: #c1c1c1 !important;
  opacity: 1; /* Firefox */
  font-family: proximaNova;
  font-size: 30px;
  font-weight: 100;
}

.rbt-input-main:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c1c1c1 !important;
  opacity: 1;
  font-family: proximaNova;
  font-size: 30px;
  font-weight: 100;
}

.rbt-input-main::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c1c1c1 !important;
  opacity: 1;
  font-family: proximaNova;
  font-size: 25px;
  font-weight: 100;
}

.createPost_typeahead .rbt-input-main::-ms-input-placeholder {
  color: #c1c1c1 !important;
  opacity: 1; /* Firefox */
  font-family: proximaNova;
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  font-weight: 100;
  line-height: 18px;
  height: 32px;
}

.rbt-input-main {
  width: 100% !important;
}

.createPost_typeahead .rbt-input-main:-ms-input-placeholder {
  color: #c1c1c1 !important;
  opacity: 1; /* Firefox */
  font-family: proximaNova;
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  font-weight: 100;
  line-height: 18px;
}

.createPost_typeahead .rbt-input-main::placeholder {
  color: #c1c1c1 !important;
  opacity: 1; /* Firefox */
  font-family: proximaNova;
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  font-weight: 100;
  line-height: 18px;
}

.pill {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.modal-header {
  background: linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%);
  padding: 5px 5px;
  border: 1px solid #00c6ff;
}

.modal-title {
  color: #ffffff !important;
  font-family: proximaNova !important;
  font-size: 25px !important;
  font-weight: 300;
  line-height: 55px;
  text-transform: uppercase;
}

.dropdown-divider {
  border-top: 1px solid #eceaea !important;
}

.react-phone-number-input__icon-image {
  vertical-align: top !important;
}
.set::after {
  position: sticky;
  top: 0;
  background-color:black;
}

.react-phone-number-input__icon > svg {
  vertical-align: top !important;
}

.react-phone-number-input__input {
  border-bottom: 1px solid #4a4a4a !important;
  height: calc(2.25rem + 2px) !important;
}

.react-phone-number-input__input--disabled {
  background-color: #e9ecef !important;
}

.react-phone-number-input__phone {
  font-family: proximaNova !important;
  padding: 2px 2px !important;
  color: #4a4a4a !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.form-control {
  height: calc(2.25rem + 2px) !important;
}

.dropdown-link-container {
  /* height: 45px; */
  display: flex;
  padding: 8px !important;
}

.dropdown-item {
  margin: auto;
}

.rbt-input-multi.focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.createPost_typeahead .rbt-input {
  padding: 0 !important;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  outline: 0;
}
@media screen and (min-width: 769px) 
{.modal-content {
  margin: 0 auto;
  max-height: calc(100vh - 40px);
  width: 640px;
}
}

