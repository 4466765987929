.dept_img {
  height: 43px;
  width: 59px;
  margin: auto;
}

.dept_image_div {
  height: 122px;
  width: 122px;
  border: 3px solid #4a4a4a;
  border-radius: 50%;
  margin: auto;
  display: flex;
}

.manage-dept-new-channel input::placeholder {
  color: #c1c1c1;
  font-family: ProximaNova;
  font-size: 30px;
  font-style: italic;
  line-height: 37px;
  text-align: center;
}
