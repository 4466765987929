.user-oval {
  background-image: url("../../img/profile.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 122px;
  width: 122px;
  height: 122px;
  margin: auto;
}

.user-icon {
  height: 55px;
  width: 55px;
  border-radius: 100px;
  background-image: url("../../img/user-35.png"),
    linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%);
  background-repeat: no-repeat;
  background-position: center;
}

.mt-100 {
  margin-top: 100px;
}

.topMargin {
  margin-top: 25% !important;
}
