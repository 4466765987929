.timeline_text {
  color: #9ce7fd;
  font-family: "AlternateGothicNo1D";
  font-size: 80px;
  line-height: 70px;
  margin: auto auto 9% 20%;
}

.timelineFIlterOptions {
  color: #c1c1c1;
  font-family: "ProximaNova";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-left: 20%;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter-active {
  color: #4a4a4a;
  font-weight: bold;
}

.channel_name_container {
  margin: auto auto auto 0;
  min-width: 50%;
  max-width: 90%;
  word-break: break-all;
  /* color: #c1c1c1; */
  font-family: "ProximaNova";
  font-size: 14px;
  font-weight: bold;
  background-color: #9ce7fd;
  border-radius: 18px;
  margin-bottom: 2%;
}

.channel_name {
  margin: auto 7px auto 7px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  font-size: 12px;
}

.highlignt_text {
  color: #4a4a4a !important;
}

.normal_text {
  color: #c1c1c1 !important;
}

.create_channel_textBox {
  height: 45px;
  border-radius: 8px;
  border: 1px solid #00c6ff;
  color: black;
  padding-left: 2%;
  background-color: #f7f7f7;
  width: 100%;
}

.channel-list {
  padding-right: 5px;
  /* margin-left: 80px; */
  margin-left: 25%;
}

.rbt-token {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
}

.responsive-height {
  @media (min-height: 576px) {
    height: 25vh;
  }
  @media (min-height: 832px) {
    height: 32vh;
  }
}
