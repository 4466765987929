.disableAccountHeading {
  text-align: center;
}

.disableAccountBtn {
  /* background-image: linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%); */
  background: transparent;
  padding: 8px 30px;
  min-width: 150px;
  border-radius: 14px;
  text-align: center;
  margin-top: 80px;
  transition: all 0.3s;
  border: 1px solid #141414;
}

.disableAccountCancelBtn {
  border: 1px solid #00c6ff;
  background-image: linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%);
  color: #fff;
}

.disableAccountBtnContainer {
  display: flex;
  justify-content: center;
  column-gap: 30px;
}

.disableAccountBtn:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.disableAccountBtn:active {
  transform: scale(0.95);
}
