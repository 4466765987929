.post-container {
  padding: 25px;
  box-shadow: 0px 0px 15px rgba(24, 24, 24, 0.3);
  font-family: proximaNova;
  font-size: 15px;
}

.owner-name {
  color: #4a4a4a;
  font-family: proximaNova;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
}

.post-date {
  color: #4a4a4a;
  font-family: proximaNova;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.post-title {
  color: #4a4a4a;
  font-family: proximaNova;
  font-size: 18px;
  line-height: 22px;
}

.divider {
  box-sizing: border-box;
  height: 2px;
  width: 99%;
  border: 1px solid #c1c1c1;
}

.post-header-text {
  color: #4a4a4a;
  font-family: PTSansNarrow;
  font-size: 40px;
  font-weight: bold;
  line-height: 52px;
}
