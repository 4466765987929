.post-type {
  height: 55px;
  width: 55px;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  margin: auto auto auto 0;
  background: linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%);
}

.post-type img {
  height: 26px;
  width: 28px;
  color: #ffffff;
  margin: auto;
}

.posts_options_wrapper {
  margin: 0;
}

.attach_text {
  color: #9b9b9b;
  font-family: proximaNova;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  margin-top: 1rem;
}

.type_text {
  color: #9b9b9b;
  font-family: proximaNova;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  margin-top: 1rem;
}

/* .selected-option {
  text-align: center;
  border-radius: 18px;
  color: #4a4a4a;
  font-family: ProximaNova;
  font-weight: bold;
  font-size: 20px;
  background-color: #9ce7fd;
  width: 90%;
  margin: 2%;
} */
.selected-option {
  border: 0;
  border-radius: 18px;
  color: #4a4a4a;
  background-color: #9ce7fd;
  display: inline-block;
  line-height: 1em;
  margin: 0 3px 3px 0;
  position: relative;
  font-family: proximaNova !important;
  padding: 3px 20px;
  padding-right: 25px;
  font-size: 20px;
  font-weight: bold;
}

.link_input {
  width: 100%;
  border: 1px solid #979797;
  height: 83px;
  padding: 1%;
  margin: 16px auto auto auto;
  resize: none;
  background: #f7f7f7;
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.upload_title_input {
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
  height: 40px;
  padding-left: 2%;
  margin: 4% auto 4% auto;
}

.upload-btn {
  border-radius: 8px;
  background-color: #00c6ff;
  color: #ffffff;
  margin: auto;
}

.schedule_wrapper {
  margin: 1rem;
}

.schedule_wrapper > div > span {
  margin: auto 8px auto auto;
  color: #9b9b9b;
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 300;
}

.content_icon {
  width: 55px;
  margin: auto;
}

.publish-button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
