.popup {
  min-height: 306px;
  width: 400px;
  background: #fff;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 30px 30px;
  color: #333;
}
.popup img {
  width: 131px;
  /*margin-top: -63px;*/
}
.popup h2 {
  font-size: 38px;
  font-weight: 500;
  margin: 30px 0 10px;
}

.buttons {
  width: 100%;
  margin-top: 50px;
  padding: 10px 0;

  background: #00c7ff;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 18px;
  border: 4px;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
