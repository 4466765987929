.post_heading {
  font-size: 36px;
  font-family: Lato;
  font-weight: 300;
  text-align: left;
  margin-left: 0%;
  color: #4a4a4a;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.post_heading_metadata {
  text-align: left;
  margin-top: 2px;
  margin-left: 0;
  margin-right: 0;
}

.post_Author {
  margin: auto 2% auto 0%;
  font-size: 24px;
  font-family: Lato;
  font-weight: 300;
  line-height: 29px;
  color: #4a4a4a;
}

.your_posts_posts_text {
  margin: auto;
  line-height: 70px;
}

.post_author_separator {
  margin: auto 0% auto 0%;
}

.post_option_mail {
  margin: auto auto auto 0;
}

.post_Date {
  margin: auto 2%;
  font-size: 24px;
  font-family: Lato;
  font-weight: 300;
  line-height: 29px;
  color: #4a4a4a;
}

.fa-heart {
  color: #bedd1f;
}

.post_likes {
  margin: auto 2%;
  font-size: 17px;
  font-family: proximaNova;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}

.post_data_container {
  padding: 0;
  margin: 0;
}

.post_options_container {
  padding-left: 0;
}

.posts_container {
  display: flex;
}

.post_data_options {
  margin-top: 3%;
  color: #6fdcfc;
  font-size: 14px;
  margin-bottom: 3%;
}

.post_data {
  font-family: proximaNova;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
}

.post_option {
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  font-family: proximaNova;
  color: #00c6ff;
}

.post_option_save_edit {
  text-align: center;
  margin: auto 3% auto 0%;
}

.post_option_like {
  text-align: center;
  margin: auto 3% auto 0%;
}
.post_option_likes {
    text-align: center;
      margin: auto 3% auto 0%;
}
.post_option_share {
  text-align: center;
  margin: auto 3% auto 0%;
}

.post_more {
  margin: auto 0% auto auto;
  font-style: italic;
}

.post_more {
  cursor: pointer;
  color: #6fdcfc !important;
}

.post_image_container {
  text-align: center;
  margin: 2% auto auto auto;
}
.post_image {
  width: 60%;
}

.post_end_line {
  margin-left: -2%;
  margin-top: 0;
  margin-bottom: 2%;
  border: none;
  border-top: 3px solid #c7d5d8;
}

.post_sub_header {
  font-size: 80px;
  font-family: AlternateGothicNo1D;
  color: #00c6ff;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.8;
  letter-spacing: normal;
  margin-left: 6.18%;
  margin-top: 10%;
  margin-right: 0;
}

.post_sub_header_your_posts {
  font-size: 80px;
  font-family: AlternateGothicNo1D;
  color: #00c6ff;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.8;
  letter-spacing: normal;
  margin-left: 7.91%;
  margin-top: 10%;
  margin-right: 0;
}

.col-container {
  display: table;
  width: 100%;
}

.post_pagination_container {
  height: 50px;
  display: flex;
  color: #00c6ff !important;
  font-family: proximaNova;
}

.pagination_icon_disabled {
  opacity: 0.5;
  margin: auto 20px auto 0;
  pointer-events: none;
}

.pagination_icon_sforward_disabled {
  margin: auto 0;
  pointer-events: none;
  opacity: 0.5;
}

.pagination_icon_sforward {
  margin: auto 0;
  cursor: pointer;
}

.pagination_icon_sforward_disabeld {
  margin: auto 0;
  opacity: 0.5;
  pointer-events: none;
}

.pagination_icon {
  margin: auto 20px auto 0;
  cursor: pointer;
}

.pagination_icon_container {
  margin: auto;
  display: flex;
  width: 100%;
  font-size: 18px;
}

.pagination_icon_inner_container {
  margin: auto 0 auto auto;
  display: inline-flex;
}

.pagination_number_container {
  margin: auto auto auto 0;
  text-align: left;
}

.feed_icon {
  font-size: 25px;
  text-align: right;
  margin-top: 1%;
  padding-right: 1px;
}

.feed_text {
  font-size: 15px;
  text-align: left;
  padding-left: 0;
}

.feed_text_container {
  margin: auto;
  display: flex;
  width: 100%;
}

.your_posts_div {
  opacity: 1;
  display: flex;
  flex-direction: column;
  color: #9ce7fd;
}

.background_white {
  background: white;
}

.background_default {
  background: #e0f4f9;
}

.change_channel_div {
  font-size: 20px;
  width: 80%;
  display: flex;
}

.change_channel_text {
  margin: auto auto auto 1%;
  color: #00c6ff;
  font-family: proximaNova;
  font-size: 18px;
  line-height: 22px;
}

.change_channel_text_div {
  font-size: 15px;
}

.change_channel_icon {
  margin: auto 0% auto auto;
  font-size: 26px !important;
}

.channel_name_heading {
  font-size: 25px;
}

.shareNewPost_gear {
  font-size: 28px;
}

.newPostText {
  font-family: proximaNova;
  font-size: 14px;
  margin: auto auto auto 1%;
  color: #9ce7fd;
}

.channel_name_heading {
  color: #00c6ff;
  font-family: AlternateGothicNo1D;
  font-size: 100px;
  line-height: 120px;
}

.show_loader_image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stop_loader_image {
  display: none;
}

.share_post_text {
  margin: 0;
  font-size: 15px;
  font-family: Lato;
  font-weight: 300;
  line-height: 29px;
  color: #4a4a4a;
}

.noPostsText {
  font-family: proximaNova;
  font-size: 25px;
  color: #4a4a4a;
  margin-top: 15%;
}

.noSavedpostsText {
  font-family: proximaNova;
  font-size: 25px;
  color: #4a4a4a;
  margin-top: 15%;
}

.post_channel {
  font-size: 24px;
  font-family: Lato;
  font-weight: 300;
  line-height: 29px;
  color: #4a4a4a;
  margin: auto 2%;
}

.feed_textName_container {
  display: flex;
  flex-direction: column;
  margin-left: 6.18% !important;
  margin-right: 0 !important;
  margin-top: 10% !important;
}

.post_inner_container {
  margin-left: 10%;
  width: 71%;
}

.post_provider_image {
  width: 50px;
  height: 50px;
  border: 1px solid #00c6ff;
  border-radius: 50%;
  margin: 1% 0 auto 2%;
}

.individual_post {
  width: 110%;
  margin-left: auto;
  border-radius: 10px !important;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  /* box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12); */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.post_Header_Container {
  display: flex;
  flex-direction: column;
}

.post_Header {
  width: 100%;
  display: flex;
  margin-left: 1%;
}

.post_info {
  margin: auto 2% auto 4%;
  color: #4a4a4a;
  font-family: ProximaNova;
  font-size: 15px;
  text-align: left;
  /* max-width: 61%; */
  word-break: break-all;
}

.post_date {
    margin:-4px 4% auto 4%;
      color: #7b7272;
      font-family: ProximaNova;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
}
.post-channel{
  margin: -5px 4% auto 4%;
    color: #7b7272;
    font-family: ProximaNova;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
}

.post_like {
  margin: auto 2% auto 81px;
  color: #4a4a4a;
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.post_like_image {
  color: #00c6ff;
  font-size: 17px !important;
}

.post_header_text {
  margin: 1% 2% auto 1%;
  text-align: left;
  color: #090909;
  font-family: ProximaNova;
  font-size: 18px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
 
}

.post_data_divider {
  height: 1px;
  background: #c0c0c0;
  width: 100%;
}

.post_desc_header {
  text-align: left;
  margin-left: 1%;
  color: #4a4a4a;
  font-family: PTSansNarrow;
  font-size: 30px;
  font-weight: 600;
}

.post_desc_image {
  margin: 2% 2% 0 2%;
  /* max-width: 25%; */
  max-width: 100%;
}

.post_desc_image img {
  width: 100%;
}

.post_desc {
  margin: 2% 0 0 1%;
  /* width: 75%; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.post_desc_data {
  text-align: left;
  display: flex;
}

.post_desc_text {
  overflow: hidden;
  margin: 0 auto auto 0;
  color: #4a4a4a;
  font-family: ProximaNova;
  line-height: 24px;
  font-size: 18px;
}

.post_more_container {
  text-align: right;
  font-size: 15px;
}

.post_additionalOptions_container {
  display: flex;
  margin-bottom: 2%;
}

.post_option_like {
  width: 15px;
  height: 15px;
}
.post_option_likes {
  width: 37px;
  height: 23px;
}

.post_option_like_container {
  margin: auto 5% auto 5%;
  color: #00c6ff;
  width: 10%;
  cursor: pointer;
  font-family: proximaNova;
  font-size: 16px;
}

.post_option_save_container {
  cursor: pointer;
  color: #00c6ff;
  width: 10%;
  margin: auto 5% auto auto;
  font-family: proximaNova;
  font-size: 16px;
}

.post_option_share_container {
  margin: auto auto auto 5%;
  color: #00c6ff;
  width: 10%;
  cursor: pointer;
  font-family: proximaNova;
  font-size: 16px;
}

.post_option_delete_container {
  margin: auto auto auto 0%;
  color: #00c6ff;
  width: 10%;
  cursor: pointer;
  font-family: proximaNova;
  font-size: 16px;
}

.post_option_deleted_container {
  margin: auto;
  color: red;
  width: 10%;
  cursor: pointer;
  font-family: proximaNova;
  font-size: 16px;
  pointer-events: none;
}

.post_header_img_div {
  margin: auto 0 auto 2%;
}

.post_image_container img {
  width: 100%;
}

.post_document_link img {
  width: 25% !important;
  cursor: pointer;
}

.post_more_container a {
  color: #c1c1c1;
  font-family: ProximaNova;
  font-style: italic;
  font-weight: bold;
  line-height: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1110px) {
  .post_image {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .post_image {
    width: 90%;
  }
}

.post-option a {
  color: #00c6ff !important;
  cursor: pointer;
  font-family: proximaNova;
  font-size: 16px;
  line-height: 19px;
}
.profile-image {
  background-repeat: no-repeat;
  background-position: top;
  width: 50px;
  height: 50px;
  border: 1px solid #00c6ff;
  border-radius: 25px;
  background-size: cover;
}

.no-profile-image {
  background-image: url("../../img/adminUser.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 38px auto;
  width: 50px;
  height: 50px;
  border: 1px solid #00c6ff;
  border-radius: 25px;
}
