.user-oval {
  background-image: url("../../img/profile.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 122px;
  width: 122px;
  height: 122px;
  margin: auto;
}

.user-pic {
  background-image: url("../../img/profile.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 67px;
  width: 67px;
  height: 67px;
  margin: auto;
}

.rectangle-info {
  margin-right: 15px;
  border: 1px solid #eceaea;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 0 10px 0;
  cursor: pointer;
}

.user-name {
  padding: 10px 0px;
  color: #4a4a4a;
  font-family: PTSansNarrow;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
}

.loc-clinic {
  height: 42px;
  width: 116px;
  background-color: #e0f4f9;
  text-align: center;
  line-height: 42px;
}

.loc-info-txt {
  color: #4a4a4a;
  font-family: proximaNova;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}

.rbt > .form-control {
  padding: 0.9mm 2px !important;
}

.modal-size {
  width: 60vw !important; /* Occupy the 65% of the screen width */
  max-width: 60vw !important;
}

.singleSelect .rbt-input-main {
  border: none !important;
  border-bottom: 0.31mm solid #4a4a4a !important;
  border-radius: 0 !important;
}

.org_provider_img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 67px;
  width: 67px;

  margin: auto;
  border-radius: 50%;
  border: 3px solid black;
}
