.shareExsistingPostText {
  width: 100%;
  margin: 3% auto;
  border-radius: 7px;
  font-size: 14px;
}

.shareButton {
  background: #00c6ff !important;
  border-color: #00c6ff !important;
}

.shareExsistingPostText textarea::placeholder {
  color: #c1c1c1 !important;
  opacity: 1;
  font-family: proximaNova !important;
  font-size: 18px !important;
  font-weight: 100;
  font-style: unset;
}

.postcreate_typeahead ::placeholder {
  color: #c1c1c1 !important;
  opacity: 1;
  font-family: proximaNova !important;
  font-size: 18px !important;
  font-weight: 100;
}
