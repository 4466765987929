.inProgress {
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
  background-color: #1f91d2 !important;
  box-shadow: none !important;
  border-color: transparent !important;
  color: black !important;
  margin-left: 72px;
  padding: 0.335rem 1.75rem 0.43rem;
}
