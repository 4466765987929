.logo {
  /*height: 69px;*/
  height: 40px;
  width: 200px;
}

.navbar {
  min-height: 85px;
  position: sticky;
  top: 0;
  /* background: rgb(0, 200, 255); */
  height: 60px;
  margin-bottom: 20px;
  padding: 0 20px;
  z-index: 10;
  
  /* for positioning text, links, etc */
  display: flex;
  align-items: center;
}

.headerBorder {
  box-sizing: border-box;
  border-bottom: 2px solid #00c6ff;
  
  /* box-shadow: 0 1px 1px 0 rgba(0,0,0,0.5); */
}

.healthswimWord {
  width: 196px;
  margin-left: 12.43%;
}

.sign-in-sign-up > a,
.sign-in-sign-up > span {
  color: #4a4a4a;
  font-family: PTSansNarrow;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  text-decoration: none;
}

.sign-in-sign-up > a:hover,
.sign-in-sign-up > span:hover {
  text-decoration: none;
}

.profile-info-container {
  width: 17%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.profile-img-container {
    width: 45px;
      height: 45px;
      border-radius: 50px;
      /* border: 1px solid #00C6FF; */
      text-align: center;
      margin: auto;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      margin-top: 5px;
}

.profile-name {
  margin-top: 05px;
  color: #4a4a4a;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
}

.dropdown button {
  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  color: #4a4a4a;
  background-color: transparent;
  padding: 5px;
}

.dropdown button:focus {
  border-color: #fff !important;
  box-shadow: none !important;
}

.drop-dwon-p {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 42px, 0px);
}

.dropdown-menu {
  border-radius: 0 !important;
}

.dropdown-menu a {
  font-family: Lato;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
}

.dropdown-menu a:active {
  background-color: #fff;
  color: #4a4a4a;
}

.dropdown-menu a {
  color: #4a4a4a;
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}

.dropdown-item {
  padding: 8px !important;
}

.dropdown-toggle::after {
  content: none !important;
}

.dropdown-divider {
  margin: 0 !important;
}
.no-profile-img {
  background-image: url("../../img/adminUser.png");
  width: 60px;
  height: 40px;
  border-radius: 50%;
  /* border: 1px solid #00C6FF;  */
  text-align: center;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
}

.profile_name_display {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #4a4a4a;
  font-family: ProximaNova;
  font-size: 11px;
  font-weight: 500;
  margin: auto;
  line-height: 15px;
}

.dropdown {
  display: flex !important;
}

#dropdown-menu {
  width: 150% !important;
  margin: auto;
}

.dropdown-menu {
  margin: auto !important;
}

@media screen and (min-width: 768px) {
  .sign-in-sign-up{
    margin-right: 25%;
  }
}

@media screen and (max-width: 767px) {
  .sign-in-sign-up{
    margin-right: 40%;
  }
}




.headers {
  position: sticky;
  top: 0;
  background: rgb(0, 200, 255);
  height: 60px;
  margin-bottom: 20px;
  padding: 0 20px;
  z-index: 10;
  
 
  display: flex;
  align-items: center;
}