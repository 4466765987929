.infinite_scroll_div {
  height: "700px";
  overflow: "auto";
  padding-right: "8px";
  padding-left: "8px";
  height: -moz-calc(60vh);
  height: -webkit-calc(60vh);
  height: calc(60vh);
}

.noFeedText {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: proximaNova;
  transform: translate(-50%, -50%);
  color: #4a4a4a;
  font-size: 30px;
}
