.header-width {
  width: 475px;
  margin: 0 auto;
}

.rectangle-text > p {
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4a4a4a;
  margin: 10px;
}
.link
{
  color: white;
  text-decoration: none;
}
.link:hover{
  text-decoration: underline;
  color: white;
}
.heading{
font-weight:800;
color:black;
font-size: 15px;
}
