.adminProfile_img {
  width: 105px;
  display: inline-block;
  margin: 16px 18px;
}

.dummyProfile_img {
  width: 80px;
  display: inline-block;
  margin: 16px 18px;
}

.profileImg-container {
  margin: auto;
  width: 122px;
}

.rbt .form-control {
  height: auto !important;
}
