.org_logo {
  height: 105px;
  width: 105px;
  /* border: 2px solid black; */
  margin: auto;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.rbt-input-main{
  font-size: 20px !important;
  height: calc(2.25rem + 2px);
}