.location-oval {
  background-image: url("../../img/location.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 57px;
}

.rectangle-info {
  margin-right: 15px;
  border: 1px solid #eceaea;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 0 10px 0;
}

.loc-name {
  padding: 10px 20px;
  color: #4a4a4a;
  font-family: PTSansNarrow;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
}

.loc-clinic {
  min-height: 42px;
  width: 100% !important;
  background-color: #e0f4f9;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 100% !important;
}

.loc-info-txt {
  color: #4a4a4a;
  font-family: proximaNova;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}

.rbt > .form-control {
  padding: 0.9mm 2px !important;
}

.modal-size {
  width: 65vw !important; /* Occupy the 90% of the screen width */
  max-width: 65vw !important;
}

.remove-deptadmin {
  color: red;
  position: absolute;
  right: 25px;
  top: 0px;
  cursor: pointer;
}
.edit_location_typeahead .rbt-input-hint-container {
  width: 10px !important;
}
