.connect {
  height: 57px;
  width: 57px;
  border-radius: 100px;
  background: linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%);
  font-size: 42px;
  color: #fff;
  text-align: center;
  margin: 15px 0;
}

.facebook-icon {
  height: 57px;
  width: 57px;
  border-radius: 100px;
  background-image: url("../../img/facebook-logo.png"),
    linear-gradient(0deg, #00e5ff 0%, #00c6ff 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
}

.profileInfo-oval {
  background-image: url("../../img/profile_details.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 57px;
}
