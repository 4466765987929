.shareNewPostText {
  font-family: AlternateGothicNo1D;
  margin: auto;
  color: #9ce7fd;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
}

.shareNewPost_gear {
  margin: auto 1% auto auto;
}

.text_color {
  color: #7fe2ff;
}

.text_center {
  text-align: center;
}

.inline_block {
  display: inline-block;
}

.shareNewPostTextContainer {
  /* margin: auto 0 auto 6.18%; */
}

.newPostForm {
  /* margin-left: 4.68%; */
  margin-top: 88px;
  display: flex;
  width: 41.04%;
}

.managePostsText {
  font-family: ProximaNova;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #00c6ff;
  margin: auto auto auto 1%;
  color: #9ce7fd;
  font-size: 14px;
}

.newPostTextArea {
  height: 169px;
  width: 100%;
  /* border-radius: 10px; */
  /* border: 1px solid #006493; */
  margin-right: 1%;
  background: #f7f7f7;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.left_float {
  float: left !important;
}

.newPostContainer {
  margin: 0;
}

.newPostoptionsContainer {
  height: 269px;
  background: linear-gradient(180deg, #00c6ff 0%, #4a90e2 100%);
  width: 28.81%;
  border-radius: 7px;
  margin: 88px 0 96px 0.5%;
}
textarea::-webkit-input-placeholder {
  padding-left: 20px;
  padding-top: 20px !important;
  color: #c1c1c1;
  font-family: proximaNova;
  font-size: 18px;
  font-style: italic;
  /* font-weight: bold; */
  line-height: 22px;
}

textarea::-moz-placeholder {
  padding-left: 20px;
  padding-top: 20px !important;
  color: #c1c1c1;
  font-family: proximaNova;
  font-size: 18px;
  font-style: italic;
  /* font-weight: bold; */
  line-height: 22px;
  text-indent: 3.8%;
}

.newPostTextAreaContainer input::-webkit-input-placeholder {
  padding-left: 35px;
  font-size: 15px;
  font-size: 20px;
  padding-top: 10px !important;
  color: #dadada;
  font-style: italic;
}

.newPostTextAreaContainer input::-moz-placeholder {
  padding-left: 35px;
  font-size: 15px;
  font-size: 20px;
  padding-top: 10px !important;
  color: #dadada;
  font-style: italic;
}

.selectedBorder {
  border: 2px solid #006493;
}

.addOptionsText {
  text-align: left;
  color: #ffffff;
  font-family: proximaNova;
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.share_post_url_container {
  padding: 0;
  border-bottom: 2px solid #00c6ff;
  background: white !important;
}

.newPostOptionsDiv {
  position: relative;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  display: flex;
}

.newPostOptionsIcon {
  width: 28px;
  height: 26px;
  margin: auto;
}

.newPostOptionsIcon_Image {
  width: 32px;
  height: 26px;
  margin: auto;
}

.newPostOptionsIcon_Video {
  width: 30px;
  height: 26px;
  margin: auto;
}

.newPostOptionsIcon_Link {
  width: 37px;
  height: 26px;
  margin: auto;
}

.postShare_selectbox {
  width: 87% !important;
  margin-top: 5%;
  background: #f7f7f7;
  font-size: 15px !important;
  text-align: left;
  padding-left: 2%;
  font-family: proximaNova !important;
}

.channel_select_Menu {
  max-height: 150px !important;
}

.post_share_button {
  background: #00c6ff !important;
  display: inline-block;
  margin: auto 0 auto 3%;
  height: 32px;
  width: 91px;
  height: 32px;
  border-radius: 4px;
  font-family: proximaNova !important;
  font-size: 14px !important;
}

.post_calender {
  height: 33px;
  width: 33px;
}

.post_share_button_container {
  margin: 25px auto auto auto;
  text-align: right;
  display: flex;
  width: 90%;
}

.post_share_calender {
  color: #f7f7f7;
  font-size: 20px;
  display: inline-block;
  margin: auto 3% auto auto;
}

.preview_container {
  /* background: white; */
  background: #f7f7f7;
  height: 80%;
  margin-top: 2%;
  width: 96%;
  margin-left: 2%;
  padding-top: 2%;
  position: relative;
}

.preview_image {
  width: 65px;
  margin-left: 2%;
  margin-right: 2%;
}

.preview_title {
  text-align: left;
  font-weight: bold;
  font-size: 13px;
}

.preview_desc {
  text-align: left;
  margin-top: 2%;
  margin-left: 2%;
  font-size: 12.5px;
}

.preview_link {
  margin-left: 2%;
  text-align: left;
}
.preview_close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 5px;
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.new_post_options {
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
}

.upload_new_post_image {
  display: none !important;
}

.selectMenu {
  max-height: 100px !important;
}

.newPostTextBox {
  margin: auto;
  width: 100%;
  height: 151px;
  border-radius: 10px !important;
  border: 1px solid #979797;
  margin-right: 1%;
  background: #f7f7f7;
  resize: none !important;
  padding: 1%;
}

.newPostTextBoxPreviewLoaded {
  margin: auto;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #006493;
  margin-right: 1%;
  background: #f7f7f7;
}

.newPostTextAreaContainer {
  width: 100%;
}

.shareNewPostTextContainer {
  display: flex;
  flex-direction: column;
}

.setting_newpost {
  width: 20px;
  height: 20px;
  opacity: 0.5;
}

.manage_posts_div {
  display: flex;
  width: 100%;
  margin-top: -20% !important;
}

.image_upload_title_input {
  width: 100%;
  border: 1px solid #006493;
  border-radius: 8px;
  height: 40px;
  padding-left: 2%;
  margin: 4% auto auto auto;
}

.link_paste_input {
  width: 100%;
  border: 1px solid #006493;
  border-radius: 8px;
  height: 100px;
  padding-left: 2%;
  margin: 4% auto auto auto;
}

.image_upload_button {
  margin: 8% auto auto auto;
}

.uploadDialogClose {
  font-size: 20px;
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
}

.upload_btn {
  margin: 10px auto auto auto;
  width: 40%;
}

.postcreate_typeahead {
  margin: 4% auto auto auto;
}

.createPost_typeahead > div {
  height: 32px !important;
  border-bottom: none !important;
}

.createPost_typeahead .rbt-input-main {
  font-family: ProximaNova;
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  padding-left: 10% !important;
  width: 1opx !important;
}

.createPost_typeahead .rbt-input-multi .rbt-input-wrapper {
  margin-top: -4px;
}

.btn-createPost {
  height: 32px;
  width: 91px;
}

.btn-createPost > span {
  color: #ffffff;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
}

.new-post-container {
  /* padding: 30px 30px; */
  border-bottom: #00c6ff 2px solid;
}

.add-attachment-rectangle {
  background: linear-gradient(180deg, #00c6ff 0%, #4a90e2 100%);
  width: 100%;
  border-radius: 7px;
  padding: 25px 25px 15px 25px;
}
